<template>
  <div>
    <Header />
    <section class="section1">
      <!-- <div class="text-box">
        <div class="h4">多种会员营销方式</div>
        <div class="desc mb-1">
          多种营销组合出击，留住老会员引爆新客户
        </div>
      </div> -->
    </section>

    <section>
      <div class="container container-space-y-s">
        <div class="h1"
             align="center">多种会员营销方式</div>
        <p class="h4"
           align="center">多种营销组合出击，留住老会员引爆新客户</p>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;"
                        shadow="1">
          <img style="width: 100%;"
               src="~@/assets/images/crm_marketing_01.png">
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;"
                        shadow="3">
          <img style="width: 100%;"
               src="~@/assets/images/crm_marketing_02.png">
        </device-browser>
      </div>
    </section>
    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import DeviceBrowser from '@/components/device-browser'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'Marketing',
  components: {
    Header,
    Footer,
    DeviceBrowser,
    BannerRegistration
  }
}

</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  width: 100%;
  height: 450px;
  background: url("~@/assets/images/banner04.jpg");
  background-size: 100%;
  background-position: center;
  color: #fff;

  .text-box {
    position: absolute;
    left: 16%;
    top: 100px;

    .desc {
      color: #c5c5c5;
    }
  }
}
</style>
